// import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
// import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
// import HowToRegIcon from '@mui/icons-material/HowToReg';
// import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
// import AddTaskIcon from '@mui/icons-material/AddTask';
// import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PeopleIcon from '@mui/icons-material/People';
// import ArticleIcon from '@mui/icons-material/Article';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LanguageIcon from '@mui/icons-material/Language';
import RedeemIcon from '@mui/icons-material/Redeem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';
// import ContactsIcon from '@mui/icons-material/Contacts';
// import CategoryIcon from '@mui/icons-material/Category';
// import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

const menuItems = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: ViewModuleIcon,
        link: '/admin/dashboard',
        cod: 'ADM1'
      },
      {
        name: 'Clases',
        icon: CalendarMonthIcon,
        link: '/admin/calendar',
        cod: 'ADM2'
      },
      {
        name: 'Clientes',
        icon: PeopleIcon,
        link: '/admin/clients',
        cod: 'ADM3'
      },
      {
        name: 'Personal',
        icon: AccountBoxIcon,
        link: '/admin/personal',
        cod: 'ADM4'
      },
      {
        name: 'Página Web',
        icon: LanguageIcon,
        link: '/admin/web',
        items:[
            {
                name: 'Integraciones',
                icon: KeyboardArrowRightIcon,
                link: '/admin/web/integrations',
                cod: 'ADM5'
            },
            {
                name: 'Navegador de páginas',
                icon: KeyboardArrowRightIcon,
                link: '/admin/web/navigator',
                cod: 'ADM6'
            },
            {
                name: 'Estilos',
                icon: KeyboardArrowRightIcon,
                link: '/admin/web/color-pallete',
                cod: 'ADM7'
            },
        ]
      },
      {
        name: 'Promociones y regalos',
        icon: RedeemIcon,
        link: '/admin/gifts',
        cod: 'ADM9'
      },
      {
        name: 'Comunicaciones',
        icon: NotificationsIcon,
        link: '/admin/communications',
        items:[
            {
                name: 'Comunicaciones',
                icon: KeyboardArrowRightIcon,
                link: '/admin/communications/notifications',
                cod: 'ADM10'
            },
            {
                name: 'Noticias',
                icon: KeyboardArrowRightIcon,
                link: '/admin/communications/news',
                cod: 'ADM11'
            }
        ]
      },
      {
        name: 'Reportes',
        icon: AssessmentIcon,
        link: '/admin/reports',
        items:[
            {
                name: 'Reportes Basicos',
                icon: KeyboardArrowRightIcon,
                link: '/admin/reports/basic',
                cod: 'ADM12'
            },
            {
                name: 'Reportes Avanzados',
                icon: KeyboardArrowRightIcon,
                link: '/admin/reports/advanced',
                cod: 'ADM13'
            }
        ]
      },
      {
        name: 'Configuracion',
        icon: SettingsIcon,
        link: '/admin/configuration',
        items:[
            // {
            //     name: 'Salas',
            //     icon: KeyboardArrowRightIcon,
            //     link: '/admin/configuration/rooms',
            //     cod: 'ADM14'
            // },
            {
                name: 'Disciplinas',
                icon: KeyboardArrowRightIcon,
                link: '/admin/configuration/disciplines',
                cod: 'ADM15'
            }
            ,
            {
                name: 'Planes',
                icon: KeyboardArrowRightIcon,
                link: '/admin/configuration/plans',
                cod: 'ADM16'
            }
            ,
            {
                name: 'Pagos',
                icon: KeyboardArrowRightIcon,
                link: '/admin/configuration/payments',
                cod: 'ADM17'
            }
            ,
            // {
            //     name: 'Información de cliente',
            //     icon: KeyboardArrowRightIcon,
            //     link: '/admin/configuration/set-values',
            //     cod: 'ADM18'
            // }
            // ,
            {
                name: 'Establecimiento',
                icon: KeyboardArrowRightIcon,
                link: '/admin/configuration/establishment',
                cod: 'ADM19'
            }
            ,
            {
                name: 'Roles',
                icon: KeyboardArrowRightIcon,
                link: '/admin/configuration/roles',
                cod: 'ADM20'
            }
            ,
            {
                name: 'Mensajes Automaticos',
                icon: KeyboardArrowRightIcon,
                link: '/admin/configuration/messages',
                cod: 'ADM21'
            }
            ,
            // {
            //     name: 'Web',
            //     icon: KeyboardArrowRightIcon,
            //     link: '/admin/configuration/web',
            //     cod: 'ADM22'
            // }
            // ,
            // {
            //     name: 'Cierre de Caja',
            //     icon: KeyboardArrowRightIcon,
            //     link: '/admin/configuration/cashier-balancing',
            //     cod: 'ADM23'
            // }
            // ,
            {
                name: 'Sala personalizada',
                icon: KeyboardArrowRightIcon,
                link: '/admin/configuration/custom-room',
                cod: 'ADM24'
            }
            ,
            {
                name: 'Credenciales',
                icon: KeyboardArrowRightIcon,
                link: '/admin/configuration/credentials',
                cod: 'ADM25'
            }
        ]
      },
      {
        name: 'Eventos',
        icon: StorageIcon,
        link: '/admin/events',
        cod: 'ADM26'
      },
    ]
  }
];

export default menuItems;