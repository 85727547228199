// import { useRef, useState } from 'react';
// import { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
// import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import {
  // Avatar,
  Box,
  Typography,
  // Button,
  // Divider,
  // alpha,
  // List,
  // ListItem,
  // ListItemText,
  // Popover,
  // Typography,
  // styled
} from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
// import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
// import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
// import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';

import cover from './admin.png';


// const UserBoxButton = styled(Button)(
//   ({ theme }) => `
//     padding: ${theme.spacing(1)};
//     background-color: ${alpha(theme.colors.alpha.black[100], 0.08)};

//     .MuiButton-label {
//       justify-content: flex-start;
//     }

//     &:hover {
//       background-color: ${alpha(theme.colors.alpha.black[100], 0.12)};
//     }
// `
// );

// const MenuUserBox = styled(Box)(
//   ({ theme }) => `
//     background: ${theme.colors.alpha.black[5]};
//     padding: ${theme.spacing(2)};
// `
// );

// const UserBoxText = styled(Box)(
//   ({ theme }) => `
//     text-align: left;
//     padding-left: ${theme.spacing(1)};
// `
// );

// const UserBoxLabel = styled(Typography)(
//   ({ theme }) => `
//     font-weight: ${theme.typography.fontWeightBold};
//     color: ${theme.sidebar.menuItemColor};
//     display: block;

//     &.popoverTypo {
//       color: ${theme.palette.secondary.main};
//     }
// `
// );

// const UserBoxDescription = styled(Typography)(
//   ({ theme }) => `
//     color: ${alpha(theme.sidebar.menuItemColor, 0.6)};

//     &.popoverTypo {
//       color: ${theme.palette.secondary.light};
//     }
// `
// );

function SidebarTopSection() {
  // const { t } = useTranslation();

  // const navigate = useNavigate();
  // const location = useLocation();
  const { user } = useAuth();

  // const ref = useRef(null);
  // const [isOpen, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleLogout = async () => {
  //   try {
  //     handleClose();
  //     await logout();
  //     navigate('/');
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

//  useEffect(()=>{
//   console.log(user);
//  },[]);

  return (
    <>
      <Box sx={{
          backgroundImage:`url(${cover})`,
          backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          height: '212px',
          // width: '100%'
          }}>
            <Box
            display="flex" justifyContent='center' alignItems='center' flexDirection='column'>
            <img src='https://media.bclassclient.com/about_us_big.jpg' alt=""
              style={{height:'130px',width:'170px',marginTop:'20px'}}
              sx={{mb:0}}
              />
              <Typography sx={{color:'#FFFFFF',fontSize:'13px',fontWeight:'bold'}}>
                Usuario:
              </Typography>
              <Typography sx={{color:'#FFFFFF',fontSize:'13px',fontWeight:'bold',textTransform: 'uppercase',textAlign:'center'}}>
               {user.nombres}  {user.apellido_p}
              </Typography>
            </Box>
      </Box>
    </>
  );
}

export default SidebarTopSection;
