import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Admin

// const Associates = Loader(lazy(() => import('src/content/admin/associates')));
// const Members = Loader(lazy(() => import('src/content/admin/members')));
// const Students = Loader(lazy(() => import('src/content/member/students')));
const Dashboard = Loader(lazy(() => import('src/content/admin/dashboard/base')));
// const Roles= Loader(lazy(() => import('src/content/admin/roles/base')));

const adminRoutes = [
  {
    path: '/',
    element: <Navigate to="integrations" replace />
  },
  {
    path: 'integrations',
    element: <Dashboard/>
  },
  {
    path: 'navigator',
    element: <Dashboard/>
  },
  {
    path: 'color-pallete',
    element: <Dashboard/>
  },
];

export default adminRoutes;