import { useEffect, useState,useContext } from 'react';
import { ListSubheader, Box, List, styled } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
// import certifyAxios from 'src/utils/aimAxios';
import certifyAxios from 'src/utils/aimAxios';
import { Context } from 'src/layouts/AccentHeaderLayout';
import SidebarMenuItem from './item';
// import menuItems from './studentItems';
// import studentItems from './studentItems';
import managerItems from './managerItems';
// import associatedItems from './associatedItems';
// import associatedRestrictedItems from './associatedRestrictedItems';
// import adminItems from './adminItems';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      margin-bottom: ${theme.spacing(1.5)};
      padding: 0;

      & > .MuiList-root {
        padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
      }
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemIconColor};
      // padding: ${theme.spacing(1, 3.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 0px 0;
        padding-left: ${theme.spacing(0)};
        // padding-right: ${theme.spacing(2)};

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.5)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          font-size:13px;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1, 3, 1, 3)};
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 50px;
          border-top-right-radius: 50px;
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7.5)};
          }
        }

        .MuiCollapse-root {
        // height:350px;
          width: 100%;
          // padding-bottom:0;
          // margin-bottom:0;
          .MuiList-root {
            // padding: ${theme.spacing(1, 0)};
            padding-bottom:0;
            margin-bottom:0;
          }

          .MuiCollapse-vertical{
            
          } 

          .MuiListItem-root {
            // padding: 0;
            // padding-left: ${theme.spacing(1.2)};

            // .MuiListItem-root {
            //   padding: 0;
            // }

            // .MuiButton-root {
            //   margin-left:0.5vw
            // }

            // .MuiButton-root {
            //   .MuiBadge-root {
            //     right: ${theme.spacing(0)};
            //   }
            // }
              .MuiButton-startIcon {
                font-size: ${theme.typography.pxToRem(20)};
                margin-right: ${theme.spacing(0)};
                color: ${theme.sidebar.menuItemIconColor};
              }

            .MuiButton-root {
              // padding: ${theme.spacing(0.7, 3, 0.7, 5.5)};
                // padding:0;
                 padding-left:50px;
                 font-size:11.5px;
                //  padding-bottom:0;
              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBgActive};
                color: ${theme.sidebar.menuItemColorActive};
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({ items, path }) => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({ ev, path, item }) => {
  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [items, setItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [bloqueo,setBloqueo] = useContext(Context);

  useEffect(() => {
    const initialize = async () => {
      // if (user.person.role === 0) setItems(studentItems);

      let aux = JSON.parse(localStorage.getItem("routes"));
      if (aux!== null) {
        // let x = JSON.parse(aux);
        let SesionItems = [{heading:'',items:[]}];
        for(let i=0;i<managerItems[0].items.length;i++){
          if(Object.hasOwn(managerItems[0].items[i],'items')){
            let x = false;
            let sub = [];
            let fase = {
              name:managerItems[0].items[i].name,
              icon:managerItems[0].items[i].icon,
              link:managerItems[0].items[i].link,
              items:[]
            }
            for(let j = 0;j<managerItems[0].items[i].items.length;j++){
              if(aux.data.list.some(e => e.codigo === managerItems[0].items[i].items[j].cod)){
                x = true;
                sub.push(managerItems[0].items[i].items[j]);
              }
            }
            if(x) {
              fase.items = sub;
              SesionItems[0].items.push(fase);
            }
          } 
        else if (aux.data.list.some(e => e.codigo === managerItems[0].items[i].cod)) 
          {
            SesionItems[0].items.push(managerItems[0].items[i]);
          } 
        }
        // console.log(SesionItems);
        setItems(SesionItems);
        // console.log("Ya conozco las rutas");
      }
      else{
        const reqObj = {
          "id_rol":user.person.role
        };
        setBloqueo(true);
        const responseActive = await certifyAxios.post('/person/role/views', reqObj);
        setBloqueo(false);
        localStorage.setItem("routes",JSON.stringify(responseActive));
        
        // localStorage.setItem("Routes",JSON.stringify(responseActive));
        // Vamos a crear el mismo arreglo de los items de forma dinamica 1 vez por sesion

        let SesionItems = [{heading:'',items:[]}];
        for(let i=0;i<managerItems[0].items.length;i++){
          if(Object.hasOwn(managerItems[0].items[i],'items')){
            let x = false;
            let sub = [];
            let fase = {
              name:managerItems[0].items[i].name,
              icon:managerItems[0].items[i].icon,
              link:managerItems[0].items[i].link,
              items:[]
            }
            for(let j = 0;j<managerItems[0].items[i].items.length;j++){
              if(responseActive.data.list.some(e => e.codigo === managerItems[0].items[i].items[j].cod)){
                x = true;
                sub.push(managerItems[0].items[i].items[j]);
              }
            }
            if(x) {
              fase.items = sub;
              SesionItems[0].items.push(fase);
            }
          } 
        else if (responseActive.data.list.some(e => e.codigo === managerItems[0].items[i].cod)) 
          {
            SesionItems[0].items.push(managerItems[0].items[i]);
          } 
        }
        // console.log(SesionItems);
        
        setItems(SesionItems);
        // console.log("No conozco las rutas");
      }
      // if (user.person.role === 1) setItems(managerItems);
      // if (user.person.role === 2){
      //   const isEnable = await isEnableAssociated()
      //   if(isEnable){
      //     setItems(associatedItems);
      //   } else {
      //     setItems(associatedRestrictedItems)
      //   }
      // } 
      // if (user.person.role === 3) setItems(adminItems);
    }
    initialize()
  }, []);

  // const isEnableAssociated = async () => {
  //   try {
  //     const reqObj = {
  //       "id": user.person.id 
  //     }
  //     const response = await certifyAxios.post(`/trainingModule/partner/isDone`, reqObj);
  //     if(response.data){
  //       // 0 cuando no termina capacitaciones o no hay
  //       if (response.data.isDone === 0 || response.data.isDone === null){
  //         return false
  //       }
  //       return true
  //     }
  //   } catch (error) {
  //     // ignore
  //   }
  //   // Por defecto solo se mostrará SOLO la opción de "Capacitaciones"
  //   return false
  // }

  return (
    <>
      {items.map((section) => (
        <MenuWrapper key={section.heading}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {t(section.heading)}
              </ListSubheader>
            }
          >
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname
            })}
          </List>
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
