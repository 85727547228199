import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Student

// const Mobile = Loader(lazy(() => import('src/content/services_to_client/pedidos_del_bar/mobile')));
const Dashboard = Loader(lazy(() => import('src/content/admin/dashboard/base')));

const employeeOpeRoutes = [
  {
    path: '/',
    element: <Navigate to="basic" replace />
  },
  {
    path: 'basic',
    element: <Dashboard />
  },
  {
    path: 'advanced',
    element: <Dashboard />
  },
];

export default employeeOpeRoutes;