// import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
// import { SidebarContext } from 'src/contexts/SidebarContext';

import { Box, 
  // Drawer,
   styled, 
  //  useTheme 
  } from '@mui/material';
// import Logo from 'src/components/LogoSign';

import SidebarMenu from './SidebarMenu';
import SidebarTopSection from './SidebarTopSection';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        // width: ${theme.sidebar.width};
        // min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`
);

// const TopSection = styled(Box)(
//   () => `
//         margin: 0 2px 2px 2px;
// `
// );

function Sidebar({sizeSidebar}) {
  // const { 
  //   sidebarToggle, 
  //   toggleSidebar } = useContext(SidebarContext);
  // const closeSidebar = () => toggleSidebar();
  // const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          width:sizeSidebar
        }}
        style={{ opacity: sizeSidebar!==0 ? "1" : "0",
          transition: "all .20s",}}
      >
        <Scrollbar>
            <SidebarTopSection />
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
      {/* <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box
                sx={{
                  width: 52,
                  ml: 0,
                  mt: 1,
                  mb: 3
                }}
              >
                <Logo />
              </Box>
              <SidebarTopSection />
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer> */}
    </>
  );
}

export default Sidebar;

