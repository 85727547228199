import { Box, useTheme,CircularProgress,Dialog,DialogContent,styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { createContext, useState } from 'react';
// import ThemeSettings from 'src/components/ThemeSettings';
import useAuth from 'src/hooks/useAuth';

import Sidebar from './Sidebar';
import Header from './Header';

export const Context = createContext();

const BootstrapDialog = styled(Dialog)(() => ({
  // '& .MuiDialogContent-root': {
  //   padding: theme.spacing(2),
  // },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
  "& .MuiDialog-container": {
      alignItems: "center"
    }
}));


const AccentHeaderLayout = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [sizeSidebar,SetSizeSidebar] = useState(theme.sidebar.width);
  const [bloqueo,setBloqueo] = useState(false);

  const cambiarSize = () =>{
    if(sizeSidebar===theme.sidebar.width) SetSizeSidebar(0);
     else SetSizeSidebar(theme.sidebar.width);
  }

  return (
    <>
      <Context.Provider value={[bloqueo,setBloqueo]}>
      <Header cambiarSize={cambiarSize}/>
      {user.person.role !== 0 && <Sidebar sizeSidebar={sizeSidebar}/>}
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            pl: `${user.person.role !== 0 ? sizeSidebar : 0}`
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box flexGrow={1} style={{backgroundColor:'#ECEDF0'}}>
            <Outlet />
          </Box>
        </Box>
        {/*
        <ThemeSettings />
        */}
      </Box>
      </Context.Provider>
      <BootstrapDialog
        maxWidth="xs"
        fullWidth
        open={bloqueo}
        // onClose={handleCloseConfirmacion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <DialogContent >
          <Box style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
            <CircularProgress color="primary" size="6rem"/>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default AccentHeaderLayout;
